

export const checkPermission = (permission) => {
    let currentPermission = (permission+"").split("|");
    // @ts-ignore
    let user = JSON.parse(localStorage.getItem('user'));
    let permissions = user?.permissions ?? [];
    let intersection = permissions.filter(x => currentPermission.includes(x));
    return intersection.length > 0 || (user && user.is_super);
};

export const commentNode = (el, vnode) => {
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined
    })
    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
   // vnode.data.directives = undefined
    if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el)
    }
}