
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import i18n from "@/core/plugins/i18n";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    const setLocale = (lang) => {
      //test data
      i18n.global.locale.value = lang;
      //style
      var head  = document.getElementsByTagName('head')[0];
      var link  = document.createElement('link');
      link.id   = "lang-switcher";
      link.rel  = 'stylesheet';
      link.type = 'text/css';
      link.href = localStorage.lang == 'fr'?'css/style.rtl.css':'css/style.css';
      link.media = 'all';
      head.appendChild(link);
    };
    setLocale(localStorage.lang);


    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
