// export const API_END_POINT = "http://localhost/masaft-app/public/admin/api";
// export const API_END_POINT = "https://darb.infoney.me/admin/api";
// export const API_END_POINT = "http://localhost:8000/admin/api";
export const API_END_POINT = "https://jahiz.app/admin/api";

export const APIs = {
    COUNTRY :{
        list            : `/countries` ,
        filter_options  : `/countries-list/filter-options`,
        actions         : `/countries-list/action`,

        governorates    : (id) => `/countries/${id}/governorates`,
    },
    USER :{
        list            : `/users` ,
        create          : `/users/create` ,
        store           : `/users` ,
        edit            : (id) => `/users/${id}/edit`,
        update          : (id) => `/users/${id}`,
        show            : (id) => `/users/${id}`,
        delete          : (id) => `/users/${id}`,
        verify_phone    : (id) => `/verify-phone-user/${id}`,
        filter_options  : `/users-list/filter-options`,
        activity_logs   : (id) => `/user-activity-logs/${id}`,
    },
    PROVIDER :{
        list            : `/providers` ,
        create          : `/providers/create` ,
        store           : `/providers` ,
        edit            : (id) => `/providers/${id}/edit`,
        update          : (id) => `/providers/${id}`,
        show            : (id) => `/providers/${id}`,
        delete          : (id) => `/providers/${id}`,
        verify_phone    : (id) => `/verify-phone-provider/${id}`,
        filter_options  : `/providers-list/filter-options`,
        location        : `/providers-location`,
        data_changes    : `/provider-data-changes`,
        activity_logs   : (id) => `/provider-activity-logs/${id}`,
    },
    PROVIDER_DATA_CHANGE :{
        list            : `/provider-data-changes` ,
        filter_options  : `/provider-data-changes-list/filter-options`,
        confirm          : (id) => `/provider-data-changes/${id}/confirm`,
        reject          : (id) => `/provider-data-changes/${id}/reject`,
    },
    PROVIDER_DOCUMENTS_EXPIRED :{
        list            : `/provider-documents-expired` ,
        notify          : (id) => `/provider-documents-expired/${id}/notify`,
    },
    PROVIDER_EXCEEDED_WALLET_LIMIT :{
        list            : `/provider-exceed-wallet-limit` ,
        notify          : (id) => `/provider-exceed-wallet-limit/${id}/notify`,
    },
    USER_EXCEEDED_WALLET_LIMIT :{
        list            : `/user-exceed-wallet-limit` ,
        notify          : (id) => `/user-exceed-wallet-limit/${id}/notify`,
    },
    SLIDER :{
        list            : `/sliders` ,
        create          : `/sliders/create` ,
        store           : `/sliders` ,
        edit            : (id) => `/sliders/${id}/edit`,
        update          : (id) => `/sliders/${id}`,
        show            : (id) => `/sliders/${id}`,
        delete          : (id) => `/sliders/${id}`,
        filter_options  : `/sliders-list/filter-options`,
    },
    ONBOARDING :{
        list            : `/onboardings` ,
        create          : `/onboardings/create` ,
        store           : `/onboardings` ,
        edit            : (id) => `/onboardings/${id}/edit`,
        update          : (id) => `/onboardings/${id}`,
        show            : (id) => `/onboardings/${id}`,
        delete          : (id) => `/onboardings/${id}`,
        filter_options  : `/onboardings-list/filter-options`,
    },
    OPTION :{
        list            : `/options` ,
        create          : `/options/create` ,
        store           : `/options` ,
        edit            : (id) => `/options/${id}/edit`,
        update          : (id) => `/options/${id}`,
        show            : (id) => `/options/${id}`,
        delete          : (id) => `/options/${id}`,
        filter_options  : `/options-list/filter-options`,
    },
    SERVICE :{
        list            : `/services` ,
        create          : `/services/create` ,
        store           : `/services` ,
        edit            : (id) => `/services/${id}/edit`,
        update          : (id) => `/services/${id}`,
        show            : (id) => `/services/${id}`,
        delete          : (id) => `/services/${id}`,
        filter_options  : `/services-list/filter-options`,
    },
    CATEGORY :{
        list            : `/categories` ,
        create          : `/categories/create` ,
        store           : `/categories` ,
        edit            : (id) => `/categories/${id}/edit`,
        update          : (id) => `/categories/${id}`,
        show            : (id) => `/categories/${id}`,
        delete          : (id) => `/categories/${id}`,
        filter_options  : `/categories-list/filter-options`,
    },
    UNIT :{
        list            : `/units` ,
        create          : `/units/create` ,
        store           : `/units` ,
        edit            : (id) => `/units/${id}/edit`,
        update          : (id) => `/units/${id}`,
        show            : (id) => `/units/${id}`,
        delete          : (id) => `/units/${id}`,
        filter_options  : `/units-list/filter-options`,
    },
    FAQ :{
        list            : `/faqs` ,
        create          : `/faqs/create` ,
        store           : `/faqs` ,
        edit            : (id) => `/faqs/${id}/edit`,
        update          : (id) => `/faqs/${id}`,
        show            : (id) => `/faqs/${id}`,
        delete          : (id) => `/faqs/${id}`,
        filter_options  : `/faqs-list/filter-options`,
    },
    CONTACT :{
        list            : `/contacts` ,
        filter_options  : `/contacts-list/filter-options`,
    },
    CITY :{
        list            : `/cities` ,
        create          : `/cities/create` ,
        store           : `/cities` ,
        edit            : (id) => `/cities/${id}/edit`,
        update          : (id) => `/cities/${id}`,
        show            : (id) => `/cities/${id}`,
        delete          : (id) => `/cities/${id}`,
        filter_options  : `/cities-list/filter-options`,
    },
    GOVERNORATE :{
        list            : `/governorates` ,
        create          : `/governorates/create` ,
        store           : `/governorates` ,
        edit            : (id) => `/governorates/${id}/edit`,
        update          : (id) => `/governorates/${id}`,
        show            : (id) => `/governorates/${id}`,
        delete          : (id) => `/governorates/${id}`,
        filter_options  : `/governorates-list/filter-options`,
        cities          : (id) => `/governorates/${id}/cities`,
    },
    COUPON :{
        list            : `/coupons` ,
        create          : `/coupons/create` ,
        store           : `/coupons` ,
        edit            : (id) => `/coupons/${id}/edit`,
        update          : (id) => `/coupons/${id}`,
        show            : (id) => `/coupons/${id}`,
        delete          : (id) => `/coupons/${id}`,
        filter_options  : `/coupons-list/filter-options`,
    },
    SETTING : {
        contact_us : '/settings/contact-us',
        order_setting : '/settings/order-setting',
        privacy_policy : '/settings/privacy-policy',
        terms_and_condiitons : '/settings/terms-and-conditions',
        about_us : '/settings/about-us',
        payment_setting : '/settings/payment-setting',
        social_setting : '/settings/social-setting',
        sms_setting : '/settings/sms-setting',
        pubnub_setting : '/settings/pubnub-setting'
    },
    WALLET : {
        user_transactions : (id) => `/user-wallet/${id}/transactions`,
        provider_transactions : (id) => `/provider-wallet/${id}/transactions`,
    },
    ORDER :{
        list            : `/orders` ,
        show            : (id) => `/orders/${id}`,
        filter_options  : `/orders-list/filter-options`,
    },
    ROLE :{
        list            : `/roles` ,
        create          : `/roles/create` ,
        store           : `/roles` ,
        edit            : (id) => `/roles/${id}/edit`,
        update          : (id) => `/roles/${id}`,
        show            : (id) => `/roles/${id}`,
        delete          : (id) => `/roles/${id}`,
        filter_options  : `/roles-list/filter-options`,

    },
    ADMIN :{
        list            : `/admins` ,
        create          : `/admins/create` ,
        store           : `/admins` ,
        edit            : (id) => `/admins/${id}/edit`,
        update          : (id) => `/admins/${id}`,
        show            : (id) => `/admins/${id}`,
        delete          : (id) => `/admins/${id}`,
        set_fcm_token   : 'admin/set-fcm-token',
        notifications   : 'admin/notifications',
        make_notification_read       : (id) => `admin/notifications/${id}/make-read`,
    },
    PROFILE :{
        profile            : `/profile` ,
        change_password    : `/change-password` ,

    },
    DASHBOARD :{
        dashboard            : `/dashboard` ,

    },
    HOME_SECTION :{
        list            : `/home-sections` ,
    },
    BANK :{
        list            : `/banks` ,
        create          : `/banks/create` ,
        store           : `/banks` ,
        edit            : (id) => `/banks/${id}/edit`,
        update          : (id) => `/banks/${id}`,
        show            : (id) => `/banks/${id}`,
        delete          : (id) => `/banks/${id}`,
        filter_options  : `/banks-list/filter-options`,

    },
    REJECT_REASON :{
        list            : `/reject-reasons` ,
        create          : `/reject-reasons/create` ,
        store           : `/reject-reasons` ,
        edit            : (id) => `/reject-reasons/${id}/edit`,
        update          : (id) => `/reject-reasons/${id}`,
        show            : (id) => `/reject-reasons/${id}`,
        delete          : (id) => `/reject-reasons/${id}`,
        filter_options  : `/reject-reasons-list/filter-options`,
    },
};
