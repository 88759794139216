import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "countries",
        name: "countries-list",
        component: () => import("@/views/country/List.vue"),
      },
      {
        path: "providers-location",
        name: "providers-location",
        component: () => import("@/views/provider/Location.vue"),
      },
      {
        path: "users",
        name: "users-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "users-list",
            component: () => import("@/views/user/List.vue"),
          },
          {
            path: "create",
            name: "create-user",
            component: () => import("@/views/user/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-user",
            component: () => import("@/views/user/Update.vue"),
          },
          {
            path: ":id",
            name: "view-user",
            component: () => import("@/views/user/View.vue"),
          },
        ]
      },
      {
        path: "providers",
        name: "providers-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "providers-list",
            component: () => import("@/views/provider/List.vue"),
          },
          {
            path: "create",
            name: "create-provider",
            component: () => import("@/views/provider/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-provider",
            component: () => import("@/views/provider/Update.vue"),
          },
          {
            path: ":id",
            name: "view-provider",
            component: () => import("@/views/provider/View.vue"),
          },
        ]
      },
      {
        path: "provider-data-changes",
        name: "provider-data-changes-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "provider-data-changes-list",
            component: () => import("@/views/provider/DataChangeList.vue"),
          },
        ]
      },
      {
        path: "provider-documents-expired",
        name: "provider-documents-expired-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "provider-documents-expired-list",
            component: () => import("@/views/provider/DocumentExpiredList.vue"),
          },
        ]
      },
      {
        path: "provider-exceed-wallet-limit",
        name: "provider-exceed-wallet-limit-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "provider-exceed-wallet-limit-list",
            component: () => import("@/views/provider/ExceedWalletLimitList.vue"),
          },
        ]
      },
      {
        path: "user-exceed-wallet-limit",
        name: "user-exceed-wallet-limit-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "user-exceed-wallet-limit-list",
            component: () => import("@/views/user/ExceedWalletLimitList.vue"),
          },
        ]
      },
      {
        path: "sliders",
        name: "sliders-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "sliders-list",
            component: () => import("@/views/slider/List.vue"),
          },
          {
            path: "create",
            name: "create-slider",
            component: () => import("@/views/slider/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-slider",
            component: () => import("@/views/slider/Update.vue"),
          },
          {
            path: ":id",
            name: "view-slider",
            component: () => import("@/views/slider/View.vue"),
          },
        ]
      },
      {
        path: "banks",
        name: "banks-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "banks-list",
            component: () => import("@/views/bank/List.vue"),
          },
          {
            path: "create",
            name: "create-bank",
            component: () => import("@/views/bank/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-bank",
            component: () => import("@/views/bank/Update.vue"),
          },
          {
            path: ":id",
            name: "view-bank",
            component: () => import("@/views/bank/View.vue"),
          },
        ]
      },
      {
        path: "onboardings",
        name: "onboardings-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "onboardings-list",
            component: () => import("@/views/onboarding/List.vue"),
          },
          {
            path: "create",
            name: "create-onboarding",
            component: () => import("@/views/onboarding/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-onboarding",
            component: () => import("@/views/onboarding/Update.vue"),
          },
          {
            path: ":id",
            name: "view-onboarding",
            component: () => import("@/views/onboarding/View.vue"),
          },
        ]
      },
      {
        path: "options",
        name: "options-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "options-list",
            component: () => import("@/views/option/List.vue"),
          },
          {
            path: "create",
            name: "create-option",
            component: () => import("@/views/option/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-option",
            component: () => import("@/views/option/Update.vue"),
          },
          {
            path: ":id",
            name: "view-option",
            component: () => import("@/views/option/View.vue"),
          },
        ]
      },
      {
        path: "services",
        name: "services-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "services-list",
            component: () => import("@/views/service/List.vue"),
          },
          {
            path: "create",
            name: "create-service",
            component: () => import("@/views/service/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-service",
            component: () => import("@/views/service/Update.vue"),
          },
          {
            path: ":id",
            name: "view-service",
            component: () => import("@/views/service/View.vue"),
          },
        ]
      },
      {
        path: "categories",
        name: "categories-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "categories-list",
            component: () => import("@/views/category/List.vue"),
          },
          {
            path: "create",
            name: "create-category",
            component: () => import("@/views/category/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-category",
            component: () => import("@/views/category/Update.vue"),
          },
          {
            path: ":id",
            name: "view-category",
            component: () => import("@/views/category/View.vue"),
          },
        ]
      },
      {
        path: "units",
        name: "units-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "units-list",
            component: () => import("@/views/unit/List.vue"),
          },
          {
            path: "create",
            name: "create-unit",
            component: () => import("@/views/unit/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-unit",
            component: () => import("@/views/unit/Update.vue"),
          },
          {
            path: ":id",
            name: "view-unit",
            component: () => import("@/views/unit/View.vue"),
          },
        ]
      },
      {
        path: "faqs",
        name: "faqs-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "faqs-list",
            component: () => import("@/views/faq/List.vue"),
          },
          {
            path: "create",
            name: "create-faq",
            component: () => import("@/views/faq/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-faq",
            component: () => import("@/views/faq/Update.vue"),
          },
          {
            path: ":id",
            name: "view-faq",
            component: () => import("@/views/faq/View.vue"),
          },
        ]
      },
      {
        path: "contacts",
        name: "contacts-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "contacts-list",
            component: () => import("@/views/contacts/List.vue"),
          },

        ]
      },
      {
        path: "cities",
        name: "cities-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "cities-list",
            component: () => import("@/views/city/List.vue"),
          },
          {
            path: "create",
            name: "create-city",
            component: () => import("@/views/city/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-city",
            component: () => import("@/views/city/Update.vue"),
          },
          {
            path: ":id",
            name: "view-city",
            component: () => import("@/views/city/View.vue"),
          },
        ]
      },
      {
        path: "governorates",
        name: "governorates-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "governorates-list",
            component: () => import("@/views/governorate/List.vue"),
          },
          {
            path: "create",
            name: "create-governorate",
            component: () => import("@/views/governorate/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-governorate",
            component: () => import("@/views/governorate/Update.vue"),
          },
          {
            path: ":id",
            name: "view-governorate",
            component: () => import("@/views/governorate/View.vue"),
          },
        ]
      },
      {
        path: "coupons",
        name: "coupons-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "coupons-list",
            component: () => import("@/views/coupon/List.vue"),
          },
          {
            path: "create",
            name: "create-coupon",
            component: () => import("@/views/coupon/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-coupon",
            component: () => import("@/views/coupon/Update.vue"),
          },
          {
            path: ":id",
            name: "view-coupon",
            component: () => import("@/views/coupon/View.vue"),
          },
        ]
      },
      {
        path: "settings/contact-us",
        name: "settings.contact_us",
        component: () => import("@/views/setting/ContactUs.vue"),
      },
      {
        path: "settings/order-setting",
        name: "settings.order_setting",
        component: () => import("@/views/setting/OrderSetting.vue"),
      },
      {
        path: "settings/privacy-policy",
        name: "settings.privacy_policy",
        component: () => import("@/views/setting/PrivacyPolicy.vue"),
      },
      {
        path: "settings/terms-and-conditions",
        name: "settings.terms_and_conditions",
        component: () => import("@/views/setting/TermsAndConditions.vue"),
      },
      {
        path: "settings/about-us",
        name: "settings.about_us",
        component: () => import("@/views/setting/AboutUs.vue"),
      },
      {
        path: "settings/payment-setting",
        name: "settings.payment_setting",
        component: () => import("@/views/setting/PaymentSetting.vue"),
      },
      {
        path: "settings/social-setting",
        name: "settings.social",
        component: () => import("@/views/setting/SocialSetting.vue"),
      },
      {
        path: "settings/sms-setting",
        name: "settings.sms",
        component: () => import("@/views/setting/SmsSetting.vue"),
      },
      {
        path: "settings/pubnub-setting",
        name: "settings.pubnub",
        component: () => import("@/views/setting/PubnubSetting.vue"),
      },
      {
        path: "orders",
        name: "orders.index",
        component: () => import("@/views/order/List.vue"),
      },
      {
        path: "orders/:id",
        name: "view-order",
        component: () => import("@/views/order/View.vue"),
      },
      {
        path: "roles",
        name: "roles-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "roles-list",
            component: () => import("@/views/role/List.vue"),
          },
          {
            path: "create",
            name: "create-role",
            component: () => import("@/views/role/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-role",
            component: () => import("@/views/role/Update.vue"),
          },
          {
            path: ":id",
            name: "view-role",
            component: () => import("@/views/role/View.vue"),
          },
        ]
      },
      {
        path: "reject-reasons",
        name: "reject-reasons-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "reject-reasons-list",
            component: () => import("@/views/reject_reason/List.vue"),
          },
          {
            path: "create",
            name: "create-reject-reason",
            component: () => import("@/views/reject_reason/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-reject-reason",
            component: () => import("@/views/reject_reason/Update.vue"),
          },
          {
            path: ":id",
            name: "view-reject-reason",
            component: () => import("@/views/reject_reason/View.vue"),
          },
        ]
      },
      {
        path: "admins",
        name: "admins-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "admins-list",
            component: () => import("@/views/admin/List.vue"),
          },
          {
            path: "create",
            name: "create-admin",
            component: () => import("@/views/admin/Create.vue"),
          },
          {
            path: ":id/edit",
            name: "edit-admin",
            component: () => import("@/views/admin/Update.vue"),
          },
          {
            path: ":id",
            name: "view-admin",
            component: () => import("@/views/admin/View.vue"),
          },
        ]
      },
      {
        path: "profile",
        name: "admin.profile",
        component: () => import("@/views/admin/Profile.vue"),
      },
      {
        path: "home-sections",
        name: "home_sections.index",
        component: () => import("@/views/home_section/List.vue"),
      },
      {
        path: "provider-activity-logs/:id",
        name: "provider-activity-log",
        component: () => import("@/views/provider/ActivityLog.vue"),
      },
      {
        path: "user-activity-logs/:id",
        name: "user-activity-log",
        component: () => import("@/views/user/ActivityLog.vue"),
      },
      {
        path: "notifications",
        name: "notifications-index",
        redirect: "all",
        component: () => import("@/views/content/Index.vue"),
        children: [
          {
            path: "all",
            name: "notifications-list",
            component: () => import("@/views/notification/List.vue"),
          },
        ]
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/crafted/authentication/Error403.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
