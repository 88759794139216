import { createApp } from "vue";
import App from "./App.vue";
import VueGoogleMaps from '@fawmi/vue-google-maps'
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';


//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import {getMessaging, getToken} from "firebase/messaging";
import {onMessage} from "@firebase/messaging";
import initializeApp = firebase.initializeApp;

import "@/core/plugins/prismjs";
import "bootstrap";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import mitt from "mitt";
import {checkPermission, commentNode} from "@/core/helpers/general";
import {Actions} from "@/store/enums/StoreEnums";
const emitter = mitt();

const app = createApp(App);
app.provide('emitter', emitter);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCYKj_rp0fGGRhbst13UCjTQVYJjYyMwRc',
        libraries: "places"
        // language: 'de',
    },
});

// app.use(DisableAutocomplete);
ApiService.init(app);
ApiAxiosService.init();
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

// app.directive('focus-test', {
//     mounted(el, binding, vnode, prevVnode) {
//         console.log(window.localStorage.getItem('user'))
//         if(binding.value == 'test') {
//             el.style.display = 'none'
//         }
//
//     },
// });
// @ts-ignore
let user = JSON.parse(localStorage.getItem("user"));
app.directive('can', {

    mounted(el, binding, vnode, prevVnode) {
        if (!checkPermission(binding.value)) {
            commentNode(el, vnode);
        }
    },
});

let firebaseConfig = {
    apiKey: "AIzaSyBh3S8q_TgoYtVVen0C5jX0KQgckUNg7Ro",
    authDomain: "masaft-darb.firebaseapp.com",
    databaseURL: "https://masaft-darb-default-rtdb.firebaseio.com",
    projectId: "masaft-darb",
    storageBucket: "masaft-darb.appspot.com",
    messagingSenderId: "114001503720",
    appId: "1:114001503720:web:0f293e46abe5198581f05d",
    measurementId: "G-3PJGPKR2YM"
};

const getFirebaseToken = async () => {

    // Initialize Firebase
    let firebaseApp;
    try {
        firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        const sw = await window.navigator.serviceWorker.register('firebase-messaging-sw.js');
        const token = await getToken(messaging, {
            serviceWorkerRegistration: sw
        })
        // const token = await getToken(messaging)
        onMessage(messaging, function (data) {
            emitter.emit('firebase_notification' , data);
        });

        return token;
    }catch (e) {
        // @ts-ignore
        if(e.code == "app/duplicate-app"){
            window.location.reload();
        }
        return null;
    }

}
app.provide('firebaseConfig', firebaseConfig)
app.provide('getFirebaseToken', getFirebaseToken);
if (user) {
    getFirebaseToken().then(function (token){
        store.dispatch(Actions.SET_FCM_TOKEN, token);
    }).catch(function (){

    });
}
