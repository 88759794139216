import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",

    checkbox: "aaa",
    name: "aaa",
    email: "aaa",
    company: "aaa",
    paymentMethod: "aaa",
    date: "aaa",
    actions: "aaa",


  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",

    checkbox: "fff",
    name: "fff",
    email: "fff",
    company: "fff",
    paymentMethod: "fff",
    date: "fff",
    actions: "fff",

  },
  ar: {
    profile: "الملف الشخصي",
    today_meetings: "اجتماعات اليوم",
    dashboard: "الرئيسية",
    control_panel: "لوحة التحكم",
    meetings: "الاجتماعات",
    users: "المستخدمين",
    faq: "الأسئلة الشائعة",
    onboarding: "الواجهات الافتتاحية",
    blogs: "المدونات",
    blog_categories: "تصنيفات المدونة",
    landing_resources: " العناصر العامة",
    landing_features: "المميزات العامة",
    landing_reviews: "التقييمات العامة",
    landing_client: "العملاء",
    contact_us: "تواصل معنا",
    settings: "الإعدادات",
    done: "تم",
    to_do: "لاحقا سوف يتم",
    total_hours: "إجمالي عدد الساعات",
    last_arrival_contact_messages: "رسائل التواصل المستلمة مؤخراً",
    upcoming: "المستقبلية",
    last: "الماضية",
    total: "الكلية",
    active: "مفعل",
    inactive: "غير مفعل",
    last_week: "الأسبوع الماضي",
    blog: "المدونة",
    published: "المنشورة",
    draft: "المؤرشفة",
    meetings_hours: "ساعات الاجتماعات",
    this_week: "هذا الاسبوع",
    this_day: "هذا اليوم",
    this_month: "هذا الشهر",
    hr: "ساعة",
    about: "حول",
    support: "الدعم",
    purchase: "الشراء",
    my_profile: "ملفي الشخصي",
    update_profile: "تعديل الملف الشخصي",
    change_password: "تغير كلمة المرور",
    sign_out: "خروج",
    english: "لغة انجليزية",
    arabic: "لغة عربية",
    id: "المعرِّف",
    code: "الرمز",
    title: "العنوان",
    date: "التاريخ",
    start_time: "وقت البدء",
    duration: "مدة الاجتماع",
    ended_at: "وقت الانتهاء",
    type: "النوع",
    updated_at: "اخر تحديث",
    created_at: "تاريح الانشاء",
    actions: "الإجراءات",
    instant: "فوري",
    scheduled: "مجدول",
    not_yet: "ليس بعد",
    view: "عرض",
    search: "بحث",
    meetings_list: "قائمة الاجتماعات",
    home: "الرئيسية",
    organizer: "المنظِّم",
    name: "الاسم",
    email: "البريد الالكتروني",
    path: "المسار",
    invitees_list: "قائمة المدعوِّين",
    attendees_list: "قائمة الحضور",
    recordings_list: "قائمة التسجيلات",
    meeting_details: "قائمة الأجتماعات",
    add_new_user: "إضافة مستخدم جديد",
    selected: "محدد",
    activate: "تفعيل",
    deactivate: "الغاء تفعيل",
    deselect: "الغاء التحديد",
    delete_selected: "حذف المحدد",
    show: "عرض",
    update: "تحديث",
    delete: "حذف",
    username: "اسم المستخدِم",
    status: "الحالة",
    users_list: "قائمة المستخدِمين",
    user_details: "تفاصيل المستخدِم",
    specific_user: "المستخدِم",
    profile_image: "صورة الملف الشخصي",
    remove_profile_image: "ازالة الصورة الشخصية",
    allowed_files_image: "أنواع الملفات المسموح بها: png، jpg، jpeg.",
    password: "كلمة المرور",
    please_wait: "الرجاء الإنتظار...",
    save_changes: "حفظ التغييرات",
    ok_got_it: "حسناً",
    try_again: "حاول مجدداً",
    success: "تم بنجاح",
    add_new_faq: "إضافة سؤال جديد",
    question: "السؤال",
    question_ar: "السؤال (باللغة العربية)",
    answer: "الجواب",
    answer_ar: "الجواب (باللغة العربية)",
    faqs_list: "قائمة الأسئلة الشائعة",
    faqs: "الأسئلة الشائعة",
    are_you_sure: "هل انت متأكد؟",
    faq_details: "تفاصيل السؤال",
    create_faq: "انشاء سؤال",
    add_new_resource: "إضافة عنصر جديد",
    specific_faq: "السؤال الشائع",
    update_faq: "تعديل السؤال",
    onboarding_list: "قائمة الواجهات الافتتاحية",
    resource_details: "تفاصيل العنصر",
    resource_update: "تعديل العنصر",
    image: "صورة",
    title_ar: "العنوان (باللغة العربية)",
    description: "الوصف",
    description_ar: "الوصف (باللغة العربية)",
    create_resource: "إنشاء عنصر",
    edit_resource: "تعديل العنصر",
    yes: "نعم",
    no: "لا",
    publish: "نشر",
    unpublish: "أرشفة",
    main_image: "الصورة الرئيسية",
    cover_image: "الصورة الخلفية",
    categories: "التصنيفات",
    blog_list: "قائمة المدونة",
    resource_edit: "تعديل العنصر",
    link: "رابط",
    post_categories_list: "قائمة تصنيفات المدونة",
    post_categories: "تصنيفات المدونة",
    name_ar: "الاسم (باللغة العربية)",
    landing_resources_list: "قائمة العناصر",
    resources: "العناصر",
    resource_type: "نوع العنصر",
    video: "فيديو",
    main_image_position: "موقع الصورة الرئيسية",
    top: "اعلى",
    right: "يمين",
    bottom: "اسفل",
    left: "يسار",
    video_position: "موقع الفيديو",
    section_key: "مفتاح القسم",
    landing_resource_details: "تفاصيل العنصر",
    landing_features_list: "قائمة المميزات العامة",
    icon: "الأيقونة",
    role: "دور",
    role_ar: "دور (باللغة العربية)",
    rate: "تقييم",
    landing_reviews_list: "قائمة التقييمات",
    discard: "تجاهل",
    add_new_review: "إضافة تقييم جديد",
    add_new_resouce: "إضافة عنصر جديد",
    logo: "الشعار",
    client_list: "قائمة العملاء",
    our_clients: "عملائنا",
    phone: "رقم المحمول",
    message: "رسالة",
    contact_us_list: "قائمة رسائل التواصل",
    key: "مفتاح",
    value: "قيمة",
    settings_list: "قائمة الإعدادات",
    text: "نص",
    html: "نص بصيغة منسقة",
    image_value: "صورة",
    profile_details: "تفاصيل الملف الشخصي",
    profile_edit: "تعديل الملف الشخصي",
    account: "الحساب",
    new_password: "كلمة المرور الجديدة",
    new_password_confirmation: "تأكيد كلمة المرور الجديدة",
    continue: "استمرار",
    login_success: "تم تسجيل الدخول بنجاح",
    image_ar: "صورة (باللغة العربية)",
    social_media_list: "قائمة روابط التواصل الأجتماعي",
    social_media: "روابط التواصل الاجتماعي",
    remove_icon: "ازالة الايقونة",
  },
};

!localStorage.hasOwnProperty('lang')?localStorage.setItem('lang', 'en'):null
const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
